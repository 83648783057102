<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      transition="dialog-top-transition"
    >
      <v-card>
        <!-- Necesaria para el tamano de 500 -->
        <v-form ref="form">
          <v-card
            flat
            class="mx-auto pt-5"
            max-width="600"
            :loading="validating"
            :disabled="validating"
          >
            <v-card-title>
              <h2 class="display-1 font-weight-light pt-5 pl-5">
                Crear plan de precio
              </h2>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-col cols="12" v-if="error">
                  <v-alert
                    border="left"
                    colored-border
                    type="error"
                    elevation="2"
                  >
                    <strong v-if="code">{{ code }}</strong>
                    {{ message }}
                  </v-alert>
                </v-col>

                <h3 class="pl-5 pt-5">Datos del plan</h3>

                <v-text-field
                  v-model="planName"
                  class="mt-5 mb-7 mx-5 pt-5"
                  label="Nombre plan"
                  :rules="rules.name"
                ></v-text-field>

                <v-text-field
                  v-model="planPrice"
                  class="mt-5 mb-7 mx-5 pt-5"
                  label="Precio (en centimos)"
                  :rules="rules.price"
                ></v-text-field>

                <v-textarea
                  v-model="planDescription"
                  class="mt-5 mb-7 mx-5 pt-5"
                  label="Descripción"
                  :rules="rules.description"
                ></v-textarea>

                <v-checkbox
                  v-model="active"
                  class="mt-7 mb-5 mx-5"
                  label="Activar plan"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-container>
            </v-card-text>
            <v-card-actions class="mx-5 pr-6">
              <div class="flex-grow-1"></div>
              <v-btn color="primary" dark @click="$emit('close')">
                Cerrar
              </v-btn>
              <v-btn color="primary" dark @click="save"> Guardar </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import errors from '@/mixins/errors';
import { numeric } from '../../validators/numeric';
import { required } from '../../validators/required';
import { length } from '../../validators/length';

export default {
  mixins: [errors],

  props: {
    dialog: {
      required: true,
      type: Boolean,
    },
  },

  data: () => ({
    active: false,
    planName: '',
    planPrice: '',
    planDescription: '',

    validating: false,
  }),

  computed: {
    // Validation errors
    rules() {
      return {
        name: [required(this, length(this, 150))],
        price: [required(this), numeric(this)],
        description: [length(this, 250)],
      };
    },
  },

  methods: {
    resetData() {
      this.active = false;
      this.planName = '';
      this.planPrice = '';
      this.planDescription = '';

      this.validating = false;
    },

    save() {
      this.clearErrors();

      if (!this.$refs.form.validate()) {
        return;
      }

      this.validating = true;

      const payload = {
        restaurant: this.$route.params.restaurant,
        active: this.active,
        plan_name: this.planName,
        plan_price: this.planPrice,
        description: this.planDescription,
      };

      this.$store
        .dispatch('subscriptions/createPlan', payload)
        .then(response => {
          console.log(response);
          this.$emit('saved', payload);
          this.resetData();
          this.$refs.form.resetValidation();
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          this.validating = false;
        });
    },
  },
};
</script>

<style></style>
