var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    (_vm.hasSubscription() &&
      _vm.hasPendingPayments() &&
      _vm.plan.slug === _vm.subscription.stripe_plan) ||
    (!_vm.hasPendingPayments() && !_vm.recurringPlan())
  )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var menu = ref.on;
  var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('span',[_vm._v("Opciones")])])]}}],null,false,2758148359)},[_c('v-list',{attrs:{"dense":""}},[(!_vm.hasPendingPayments())?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('subscribeTo', _vm.plan)}}},[_c('v-list-item-title',[_vm._v(" Suscríbeme ")])],1):_vm._e(),(
        _vm.hasSubscription() &&
        _vm.plan.slug === _vm.subscription.stripe_plan &&
        (_vm.subscription.stripe_status === 'incomplete' ||
          _vm.subscription.stripe_status === 'past_due')
      )?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('completePayment', _vm.plan)}}},[_c('v-list-item-title',[_vm._v(" Completar pago ")])],1):_vm._e()],1)],1):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Sin opciones")])])}
var staticRenderFns = []

export { render, staticRenderFns }