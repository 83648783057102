<template>
  <v-menu
    offset-y
    v-if="
      (hasSubscription() &&
        hasPendingPayments() &&
        plan.slug === subscription.stripe_plan) ||
      (!hasPendingPayments() && !recurringPlan())
    "
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
            <v-icon> mdi-dots-vertical </v-icon>
          </v-btn>
        </template>
        <span>Opciones</span>
      </v-tooltip>
    </template>
    <v-list dense>
      <v-list-item
        v-if="!hasPendingPayments()"
        @click="$emit('subscribeTo', plan)"
      >
        <v-list-item-title> Suscríbeme </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          hasSubscription() &&
          plan.slug === subscription.stripe_plan &&
          (subscription.stripe_status === 'incomplete' ||
            subscription.stripe_status === 'past_due')
        "
        @click="$emit('completePayment', plan)"
      >
        <v-list-item-title> Completar pago </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-tooltip top v-else>
    <template v-slot:activator="{ on: tooltip }">
      <v-btn icon v-on="{ ...tooltip }">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </template>
    <span>Sin opciones</span>
  </v-tooltip>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    subscription: {
      required: true,
    },
    plan: {
      required: true,
      type: Object,
    },
  },

  methods: {
    hasSubscription() {
      return this.subscription !== null;
    },

    hasPendingPayments() {
      return (
        this.subscription !== null &&
        (this.subscription.stripe_status === 'incomplete' ||
          this.subscription.stripe_status === 'past_due')
      );
    },

    active() {
      return (
        (this.subscription.ends_at === null || this.onGracePeriod()) &&
        this.subscription.stripe_status !== 'incomplete' &&
        this.subscription.stripe_status !== 'incomplete_expired' &&
        this.subscription.stripe_status !== 'past_due' &&
        this.subscription.stripe_status !== 'unpaid'
      );
    },

    canceled() {
      return this.subscription.ends_at !== null;
    },

    ended() {
      return this.canceled() && !this.onGracePeriod();
    },

    incomplete() {
      return this.subscription.stripe_status === 'incomplete';
    },

    onGracePeriod() {
      return (
        this.subscription.ends_at !== null &&
        dayjs(this.subscription.ends_at).diff(dayjs()) > 0
      );
    },

    onTrial() {
      return (
        this.subscription.trial_ends_at !== null &&
        dayjs(this.subscription.trial_ends_at).diff(dayjs()) > 0
      );
    },

    pastDue() {
      return this.subscription.stripe_status === 'past_due';
    },

    recurring() {
      return !this.onTrial() && !this.canceled();
    },

    recurringPlan() {
      return (
        this.plan.slug === this.subscription?.stripe_plan && this.recurring()
      );
    },
  },
};
</script>

<style></style>
