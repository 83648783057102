export const length = (component, length) => value => {
  if (value != undefined) {
    // First we need to convert any value to string
    value = value.toString();
  } else {
    // Or set it to an empty one if it's an undefined
    value = '';
  }

  return (
    value.trim().length <= length ||
    component.$t('validations.length', [length])
  );
};
