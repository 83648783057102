<template>
  <v-chip
    v-if="subscription.stripe_status !== 'canceled'"
    class="mx-2 my-1"
    color="blue-grey"
    :input-value="true"
    :filter="active"
    small
    outlined
  >
    <span v-if="hasPendingPayments">pendiente de pago</span>

    <span v-else-if="recurring"> activo </span>

    <span v-else-if="onGracePeriod"> activo / cancelado </span>

    <span v-else-if="ended"> cancelado </span>
  </v-chip>
</template>

<script>
import dayjs from 'dayjs';
export default {
  props: {
    subscription: {
      required: true,
      type: Object,
    },
  },

  computed: {
    active() {
      return (
        (this.subscription.ends_at === null || this.onGracePeriod) &&
        this.subscription.stripe_status !== 'incomplete' &&
        this.subscription.stripe_status !== 'incomplete_expired' &&
        this.subscription.stripe_status !== 'past_due' &&
        this.subscription.stripe_status !== 'unpaid'
      );
    },

    canceled() {
      return this.subscription.ends_at !== null;
    },

    ended() {
      return this.canceled && !this.onGracePeriod;
    },

    incomplete() {
      return this.subscription.stripe_status === 'incomplete';
    },

    onGracePeriod() {
      return (
        this.subscription.ends_at !== null &&
        dayjs(this.subscription.ends_at).diff(dayjs()) > 0
      );
    },

    onTrial() {
      return (
        this.subscription.trial_ends_at !== null &&
        dayjs(this.subscription.trial_ends_at).diff(dayjs()) > 0
      );
    },

    pastDue() {
      return this.subscription.stripe_status === 'past_due';
    },

    recurring() {
      return !this.onTrial && !this.canceled;
    },

    hasPendingPayments() {
      return (
        this.subscription.stripe_status === 'incomplete' ||
        this.subscription.stripe_status === 'past_due'
      );
    },
  },
};
</script>

<style></style>
