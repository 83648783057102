var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',{attrs:{"fluid":"","page":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"display-1 font-weight-light pt-5 pl-5"},[_vm._v(" "+_vm._s(_vm.$t('subscriptions.title'))+" ")])]),(_vm.error)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"border":"left","colored-border":"","type":"error","elevation":"2"}},[(_vm.code)?_c('strong',[_vm._v(_vm._s(_vm.code))]):_vm._e(),_vm._v(" "+_vm._s(_vm.message)+" ")])],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-text',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-list',{attrs:{"subheader":"","three-line":_vm.plans.lenght > 0}},[_c('v-subheader',{staticClass:"text-uppercase",attrs:{"inset":""}},[_vm._v(" Planes Disponibles ")]),_vm._l((_vm.plans),function(plan){return _c('v-list-item',{key:plan.product},[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"lighten-1"},[_vm._v(" mdi-cube-send ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(plan.nickname)+" "),(
                          _vm.subscription !== null &&
                          plan.slug === _vm.subscription.stripe_plan
                        )?_c('EasyServePaymentChip',{attrs:{"subscription":_vm.subscription}}):_vm._e()],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(plan.description)+" ")]),(
                        _vm.subscription !== null &&
                        plan.slug === _vm.subscription.stripe_plan
                      )?_c('v-list-item-subtitle',[(_vm.recurring())?_c('span',[_vm._v(" Renovación automática: "+_vm._s(_vm.formatDate(_vm.subscription.current_period_end))+" ")]):_c('span',[_vm._v(" No se cobrará mas ")])]):_vm._e()],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-list-item-action',[_vm._v(" "+_vm._s(_vm._f("money")(plan.amount))+"/mes + IVA "),_c('br'),_vm._v(" "+_vm._s(_vm._f("money")((plan.amount * 1.21)))+"/mes IVA incluido ")]):_vm._e(),_c('v-list-item-action',[_c('EasyServePaymentMenu',{attrs:{"subscription":_vm.subscription,"plan":plan},on:{"subscribeTo":function($event){return _vm.subscribeTo(plan)},"completePayment":_vm.completePayment}})],1)],1)}),(_vm.plans.length === 0)?_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"lighten-1"},[_vm._v(" mdi-cube-send ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" No hay ningún plan dado de alta. ")])],1),(_vm.isAdmin || _vm.isEmployee)?_c('v-list-item-action',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var menu = ref.on;
                      var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('span',[_vm._v("Opciones")])])]}}],null,false,2758148359)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){_vm.dialog = true}}},[_c('v-list-item-title',[_vm._v(" Crear plan de precios ")])],1)],1)],1)],1):_vm._e()],1):_vm._e()],2)],1)],1)],1)],1)],1)],1),_c('v-fab-transition',[(_vm.isAdmin || _vm.isEmployee)?_c('v-btn',{attrs:{"color":"red","fab":"","dark":"","large":"","bottom":"","right":"","ripple":"","fixed":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),(_vm.isAdmin || _vm.isEmployee)?_c('EasyServePlanDialog',{attrs:{"dialog":_vm.dialog},on:{"close":function($event){_vm.dialog = !_vm.dialog},"saved":_vm.saved}}):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"value":_vm.confirmPayment,"persistent":"","max-width":"400"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline pt-5 pb-10"},[_vm._v(" Confirmación de pago necesaria ")]),_c('v-card-text',[_vm._v(" Para confirmar el pago de "+_vm._s(_vm.subscriptionAmount)+" por favor haz clic en el boton confirmar pago. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","dark":""},on:{"click":function($event){_vm.confirmPayment = false}}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"color":"primary darken-1","dark":""},on:{"click":_vm.closeConfirmPaymentAndReload}},[_vm._v(" Confirmar pago ")])],1)],1)],1)],1),_c('eximyr-loading',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }