<template>
  <v-main>
    <v-container fluid page>
      <v-row align="center">
        <v-col cols="12">
          <h2 class="display-1 font-weight-light pt-5 pl-5">
            {{ $t('subscriptions.title') }}
          </h2>
        </v-col>

        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text>
              <v-card elevation="0">
                <v-list subheader :three-line="plans.lenght > 0">
                  <v-subheader inset class="text-uppercase">
                    Planes Disponibles
                  </v-subheader>

                  <v-list-item v-for="plan in plans" :key="plan.product">
                    <v-list-item-avatar>
                      <v-icon class="lighten-1"> mdi-cube-send </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ plan.nickname }}
                        <EasyServePaymentChip
                          v-if="
                            subscription !== null &&
                            plan.slug === subscription.stripe_plan
                          "
                          :subscription="subscription"
                        ></EasyServePaymentChip>
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        {{ plan.description }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="
                          subscription !== null &&
                          plan.slug === subscription.stripe_plan
                        "
                      >
                        <span v-if="recurring()">
                          Renovación automática:
                          {{ formatDate(subscription.current_period_end) }}
                        </span>
                        <span v-else> No se cobrará mas </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
                      {{ plan.amount | money }}/mes + IVA <br />
                      {{ (plan.amount * 1.21) | money }}/mes IVA incluido
                    </v-list-item-action>
                    <v-list-item-action>
                      <EasyServePaymentMenu
                        :subscription="subscription"
                        :plan="plan"
                        @subscribeTo="subscribeTo(plan)"
                        @completePayment="completePayment"
                      ></EasyServePaymentMenu>
                    </v-list-item-action>
                  </v-list-item>

                  <!-- NO Plans -->
                  <v-list-item v-if="plans.length === 0">
                    <v-list-item-avatar>
                      <v-icon class="lighten-1"> mdi-cube-send </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        No hay ningún plan dado de alta.
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action v-if="isAdmin || isEmployee">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on: menu, attrs }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...menu }"
                              >
                                <v-icon> mdi-dots-vertical </v-icon>
                              </v-btn>
                            </template>
                            <span>Opciones</span>
                          </v-tooltip>
                        </template>
                        <v-list dense>
                          <v-list-item @click="dialog = true">
                            <v-list-item-title>
                              Crear plan de precios
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                  <!-- NO Plans -->
                </v-list>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Create fab button -->
    <v-fab-transition>
      <v-btn
        color="red"
        fab
        dark
        large
        bottom
        right
        ripple
        fixed
        @click="dialog = !dialog"
        v-if="isAdmin || isEmployee"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <!-- Create plan dialog -->
    <EasyServePlanDialog
      v-if="isAdmin || isEmployee"
      :dialog="dialog"
      @close="dialog = !dialog"
      @saved="saved"
    ></EasyServePlanDialog>

    <v-row justify="center">
      <v-dialog :value="confirmPayment" persistent max-width="400">
        <v-card>
          <v-card-title class="headline pt-5 pb-10">
            Confirmación de pago necesaria
          </v-card-title>
          <v-card-text>
            Para confirmar el pago de {{ subscriptionAmount }} por favor haz
            clic en el boton confirmar pago.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary darken-1"
              dark
              @click="confirmPayment = false"
            >
              Cerrar
            </v-btn>
            <v-btn
              color="primary darken-1"
              dark
              @click="closeConfirmPaymentAndReload"
            >
              Confirmar pago
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
require('dayjs/locale/es');

import errors from '../mixins/errors';

import { mapGetters } from 'vuex';

import dayjs from 'dayjs';

import EximyrLoading from '../components/EximyrLoading';
import EasyServePlanDialog from './components/EasyServePlanDialog';
import EasyServePaymentChip from './components/EasyServePaymentChip';
import EasyServePaymentMenu from './components/EasyServePaymentMenu';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,
    EasyServePlanDialog,
    EasyServePaymentChip,
    EasyServePaymentMenu,
  },

  data: () => ({
    planName: '',
    planPrice: 0,

    dialog: false,
    confirmPayment: false,
    payment: {},
  }),

  computed: {
    ...mapGetters('subscriptions', {
      plans: 'plans',
      subscription: 'subscription',
      completePaymentUrl: 'completePaymentUrl',
      loading: 'loading',
    }),

    ...mapGetters('security', {
      isAdmin: 'isAdmin',
      isEmployee: 'isEmployee',
      isOwner: 'isOwner',
    }),

    subscriptionAmount() {
      return (this.payment.amount / 100).toLocaleString('es-ES', {
        style: 'currency',
        currency: 'EUR',
      });
    },
  },

  created() {
    this.load();
  },

  methods: {
    load() {
      this.clearErrors();

      this.$store
        .dispatch('subscriptions/load', this.$route.params.restaurant)
        .catch(error => {
          this.storeError(error);
          console.error(error);
        });
    },

    saved() {
      this.dialog = false;
      this.load();
    },

    subscribeTo(plan) {
      const payload = {
        restaurant: this.$route.params.restaurant,
        plan: plan,
      };

      this.$store
        .dispatch('subscriptions/subscribeTo', payload)
        .then(() => {
          this.load();
        })
        .catch(error => {
          if (error.response?.data.exception === 'PaymentActionRequired') {
            this.load();
            this.confirmPayment = true;
            this.payment = error.response?.data.payment;
            console.info(error.response);
          } else {
            this.storeError(error);
          }
        });
    },

    completePayment() {
      window.open(this.completePaymentUrl, '_blank');
    },

    closeConfirmPaymentAndReload() {
      this.confirmPayment = false;
      this.completePayment();
    },

    formatDate(date) {
      return dayjs(date).locale('es').format('DD [de] MMMM [del] YYYY');
    },

    active() {
      return (
        (this.subscription.ends_at === null || this.onGracePeriod()) &&
        this.subscription.stripe_status !== 'incomplete' &&
        this.subscription.stripe_status !== 'incomplete_expired' &&
        this.subscription.stripe_status !== 'past_due' &&
        this.subscription.stripe_status !== 'unpaid'
      );
    },

    canceled() {
      return this.subscription.ends_at !== null;
    },

    ended() {
      return this.canceled() && !this.onGracePeriod();
    },

    incomplete() {
      return this.subscription.stripe_status === 'incomplete';
    },

    onGracePeriod() {
      return (
        this.subscription.ends_at !== null &&
        dayjs(this.subscription.ends_at).diff(dayjs()) > 0
      );
    },

    onTrial() {
      return (
        this.subscription.trial_ends_at !== null &&
        dayjs(this.subscription.trial_ends_at).diff(dayjs()) > 0
      );
    },

    pastDue() {
      return this.subscription.stripe_status === 'past_due';
    },

    recurring() {
      return !this.onTrial() && !this.canceled();
    },

    hasPendingPayments() {
      return (
        this.subscription !== null &&
        (this.subscription.stripe_status === 'incomplete' ||
          this.subscription.stripe_status === 'past_due')
      );
    },
  },
};
</script>

<style></style>
